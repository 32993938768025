.page {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 70px;
  background: #ffffff;
  min-height: 100vh;
  overscroll-behavior: contain;

  &_blocked {
    position: fixed;
    z-index: 5;
  }
}

.body {
  display: flex;
  overscroll-behavior: contain;
  overflow-y: auto;
  max-height: calc(100vh - 134px);
  height: 100%;
  max-width: 100%;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
}

.reports {
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  display: flex;
  // width: 100%;
  overscroll-behavior: contain;
  overflow-y: auto;
  max-height: calc(100vh - 134px);
  max-width: 100%;
  border-left: 1px solid #d9d9d9;
  padding: 10px 0 0;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
}
