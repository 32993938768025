.page {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 70px;
  background: #ffffff;
  min-height: 100vh;
  overscroll-behavior: contain;

  &_blocked {
    position: fixed;
    z-index: 5;
  }
}

.body {
  display: flex;
  overscroll-behavior: contain;
  overflow-y: auto;
  max-height: calc(100vh - 134px);
  height: 100%;
  max-width: 100%;
  position: relative;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
}

.additional {
  display: flex;
  overscroll-behavior: contain;
  overflow-y: auto;
  max-height: calc(100vh - 134px);
  width: 100%;
  border-left: 1px solid #d9d9d9;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
}

.comments {
  // padding: 35px 31px;
  display: flex;
  flex-direction: column;
  gap: 63px;
  // min-width: 415px;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 134px);

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
}

.commTitle {
  color: #bfc8e8;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appInfo {
  padding: 0;
  height: 100%;
  max-height: 100vh;
  max-width: 433px;
  width: 100%;
  // flex-shrink: 0;
  overflow-y: scroll;
  border-left: 1px solid #d9d9d9;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
}

.loading {
  position: fixed;
  right: 40px;
  bottom: 87px;
  z-index: 2;
}
