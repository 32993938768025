:root {
  --auth-gradient: linear-gradient(180deg, #0575e6 0%, #02298a 84.79%, #021b79 100%);
  --auth-gradient0: linear-gradient(180deg, #0575e6 0%, #02298a 84.79%, #021b79 100%);
  --auth-gradient1: linear-gradient(177deg, #86f2a8 -13.49%, #2c9859 109.75%);
  --back-gradient: linear-gradient(177deg, #5c90cc -13.49%, #3a49f9 109.75%);
  --back-gradient0: linear-gradient(177deg, #5c90cc -13.49%, #3a49f9 109.75%);
  --back-gradient1: linear-gradient(177deg, #3fb68e -13.49%, #087652 109.75%);
  --button-gradient: linear-gradient(177deg, #5c90cc -13.49%, #3a49f9 109.75%);
  --button-gradient0: linear-gradient(177deg, #5c90cc -13.49%, #3a49f9 109.75%);
  --button-gradient1: linear-gradient(177deg, #86f2a8 -13.49%, #2c9859 109.75%);
  --main-color: #39558e;
  --main-color0: #39558e;
  --main-color1: #087652;
  --light-gray: #f2f2f2;
  --gray: #8391a5;
  --white: #fff;
  --blue: #0b2f7c;
  --light-blue: #00abeb;
  --app-back: #8391a5;
}
