.page {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-height: 100vh;
  position: relative;
  padding: 84px 0 0;

  &::-webkit-scrollbar {
    height: 0;
  }

  @media screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 216px 1fr;
    padding: 0 0 0;
  }
}
