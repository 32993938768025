.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 25px 0 28px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #d9d9d9;
  grid-column-start: 1;
  grid-column-end: 4;
}

.title {
  color: #363942;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 24px;
}

.servicesButton {
  padding: 4px 23px;
  border: 2px solid var(--gray);
  border-radius: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  transition: all 0.3s ease;
  position: relative;
}

.servName {
  color: var(--gray);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.servEllipsis {
  display: block;
  width: 24px;
  height: 6px;
  background: url(../../assets/subheader/subheader_services.svg) center no-repeat;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 6px 0;

  &:hover {
    opacity: 0.8;
  }
}

.servModal {
  position: absolute;
  z-index: 5;
  opacity: 0;
  transition: all 0.3s ease;

  &_opened {
    position: absolute;
    opacity: 1;
    top: -2px;
    background: #ffffff;
    min-width: 400px;
    max-width: 500px;
    padding: 15px 11px 16px 24px;
    border: 1px solid #bfc8e8;
    border-radius: 10px;
  }
}

.modalHeading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}

.modalTitle {
  color: #bfc8e8;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modalClose {
  display: block;
  width: 21px;
  height: 20px;
  background: url(../../assets/subheader/subheader_close.svg);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}

.modalItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;
  margin-bottom: 16px;
}

.modalCheckbox {
  width: 19px;
  height: 19px;
  border: 1px solid var(--gray);
}

.modalSubtitle {
  color: #363942;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.icons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 13px;
}

.call {
  display: block;
  width: 34px;
  height: 34px;
  background: url(../../assets/subheader/subheader_call.svg) center no-repeat;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  & span {
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: block;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}

.additional {
  background: url(../../assets/subheader/subheader_additional.svg) center no-repeat;
}

.attachment {
  background: url(../../assets/subheader/attachment_blue.svg) center no-repeat;
  position: relative;
}

.check {
  background: url(../../assets/subheader/subheader_check.svg) center no-repeat;
}

.new {
  background: url(../../assets/subheader/subheader_new.svg) center no-repeat;
}

.edit {
  background: url(../../assets/subheader/subheader_edit1.svg) center no-repeat;
}

.save {
  background: url(../../assets/subheader/subheader_save.svg) center no-repeat;
}

.delete {
  background: url(../../assets/subheader/subheader_delete.svg) center no-repeat;
  opacity: 0.2;

  &:hover {
    opacity: 0 !important;
  }
}
