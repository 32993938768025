.nav {
  display: flex;
  flex-direction: column;
  min-width: 216px;
  background-color: var(--gray);
  grid-row-start: 1;
  grid-row-end: 3;
  position: relative;
  transition: all 0.3s ease;

  &_hide {
    min-width: 20px;
  }
}

.extendButton {
  position: absolute;
  z-index: 100;
  right: -20px;
  top: 50px;
}

.imageLink {
  display: block;
  margin: 9px 15px 18px;
}

.logo {
  display: block;
  width: 150px;
  height: 52px;
  background: url(../../assets/navBar/company_logo.png) center no-repeat;
  background-size: 150px 52px;
  // transition: all 0.1s ease;

  &_hide {
    width: 42px;
    height: 52px;
    background: url(../../assets/navBar/company_logo_small.png) center no-repeat;
    background-size: 42px 52px;
    background-position: 0;
  }
}

.link {
  display: grid;
  grid-template-columns: 34px 1fr;
  grid-template-rows: 34px;
  justify-items: center;
  gap: 8px;
  padding: 3px 0 5px 23px;
  border-bottom: none;
  width: 100%;
  transition: all 0.3s ease;
  align-items: center;

  &_active {
    background: #378dfe;
  }

  &:hover {
    background: #378dfe;
  }
}

.applications {
  width: 29px;
  height: 29px;
  background: url(../../assets/navBar/nav_zayavki.svg) center no-repeat;
}

.users {
  width: 43px;
  height: 23px;
  background: url(../../assets/navBar/nav_users.svg) center no-repeat;
}

.chats {
  width: 42px;
  height: 42px;
  background: url(../../assets/navBar/nav_chat.svg) center no-repeat;
}

.profile {
  width: 29px;
  height: 29px;
  background: url(../../assets/navBar/nav_profile.svg) center no-repeat;
}

.services {
  width: 19px;
  height: 23px;
  background: url(../../assets/navBar/nav_services.svg) center no-repeat;
}

.reports {
  width: 22px;
  height: 22px;
  background: url(../../assets/navBar/nav_reports.svg) center no-repeat;
}

.more {
  width: 26px;
  height: 26px;
  background: url(../../assets/navBar/nav_more.svg) center no-repeat;
}

.text {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.75px;
  justify-self: flex-start;

  &_hide {
    display: none;
  }
}

.copyright {
  margin: auto auto 20px;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.75px;

  &_hide {
    display: none;
  }

  &__link {
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }
}
